import React from "react";

export const useResponsiveProgressiveBackgroundImg = (cloudinaryName, smallFilters, largeFilters) => {
  const [width, setWidth] = React.useState(300);
  const [widthInitialized, setWidthInitialized] = React.useState(false);
  const lowQualityNarrowSrc = `https://res.cloudinary.com/subscribe-sense-marketing/image/upload/e_blur:1000,c_fill,f_auto,q_auto,g_auto,${smallFilters}/${cloudinaryName}`;
  const lowQualityWideSrc = `https://res.cloudinary.com/subscribe-sense-marketing/image/upload/e_blur:1000,c_fill,f_auto,q_auto,g_auto,${largeFilters}/${cloudinaryName}`;
  const highQualitySrc = `https://res.cloudinary.com/subscribe-sense-marketing/image/upload/c_fill,f_auto,q_auto,g_auto,${width > 640 ? largeFilters : smallFilters}/${cloudinaryName}`;
  const [img, setImg] = React.useState(null);
  const css = `
  @media only screen and (max-width: 640px) {
    .blur-bg-image {
      filter: none;
      background-image: url(${lowQualityNarrowSrc});
      transition: -webkit-filter 0s 0.3s linear, filter 0s 0.3s linear;
    }
  }
  @media only screen and (min-width: 641px) {
    .blur-bg-image {
      filter: none;
      background-image: url(${lowQualityWideSrc});
      transition: -webkit-filter 0s 0.3s linear, filter 0s 0.3s linear;
    }
  }
  .bg-image-loaded {
    transition: opacity .25s linear;
  }
  .bg-header-section, .full-bg-image, .bg-image-loaded {
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;
    -webkit-filter: none;
  }
`;

  React.useEffect(() => {
    if (typeof window === 'undefined') return;

    const handleResize = () => {
      setWidth(window.innerWidth);
      setWidthInitialized(true);
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    setImg(null);
    if (widthInitialized) {
      // Don't load high-res image until width has been initialized
      const img = new Image();
      img.src = highQualitySrc;
      img.onload = () => {
        setImg(img);
      };
    }
  }, [highQualitySrc, widthInitialized]);
  return [css, img];
};

