import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, Script } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { getCloudinaryPathOnly } from "../utils/transformations";
import { BlogRollTemplate } from "../components/BlogRoll";
import Signup from "../components/EmailListSignup";
import LeadMagnetBanner from "../components/LeadMagnetBanner";
import { useResponsiveProgressiveBackgroundImg } from "../utils/hooks";
import SubSenseCollectionScript from "../components/SubSenseCollectionScript";

// eslint-disable-next-line
export const BlogPostTemplate = ({
  content,
  contentComponent,
  lead,
  leadMagnet,
  tags,
  title,
  featuredImage,
  helmet,
}) => {
  const PostContent = contentComponent || Content;
  const [css, img] = useResponsiveProgressiveBackgroundImg(featuredImage, 'w_410', 'ar_3,h_340')
  return (
    <div className="bg-white">
      {helmet || ""}
      <div id="header" className="bg-white fixed w-full z-10 top-0 hidden animated" style={{opacity: .95}}>
        <div className="bg-white">
          <div className="flex flex-wrap items-center content-center h-14">
            <div className="flex w-1/2 justify-start text-white font-extrabold">
              <a className="flex text-gray-900 no-underline hover:text-gray-900 hover:no-underline pl-2" href="#top">
                <span className="hidden w-0 md:w-auto md:block pl-1 text-indigo-600">Subscribe Sense</span>
              </a>
            </div>
          </div>
        </div>
        {/* Progress bar */}
        <Script>
        {`
          /* Progress bar */
          //Source: https://alligator.io/js/progress-bar-javascript-css-variables/
          var h = document.documentElement,
              b = document.body,
              st = 'scrollTop',
              sh = 'scrollHeight',
              progress = document.querySelector('#progress'),
              scroll;
          var scrollpos = window.scrollY;
          var header = document.getElementById("header");

          document.addEventListener('scroll', function() {

            /*Refresh scroll % width*/
            scroll = (h[st]||b[st]) / ((h[sh]||b[sh]) - h.clientHeight) * 100;
            progress.setAttribute(
              'style',
              // eslint-disable-next-line
              'background:linear-gradient(to right, #a20068 ' + scroll + '%, transparent 0);'
            );

            /*Apply classes for slide in bar*/
            scrollpos = window.scrollY;

            if(scrollpos > 100){
              header.classList.remove("hidden");
              header.classList.remove("fadeOutUp");
              header.classList.add("slideInDown");
            }
            else {
              header.classList.remove("slideInDown");
              header.classList.add("fadeOutUp");
              header.classList.add("hidden");
            }
          });
        `}
        </Script>
        <div id="progress" className="h-1 bg-white shadow"></div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6">
        {/* Title */}
        <div className="text-center pt-4 md:pt-12">
          <p className="text-sm md:text-base text-indigo-500 font-bold">Blog</p>
          <h1 className="font-bold break-normal text-3xl md:text-4xl px-5 max-w-4xl m-auto">{title}</h1>
        </div>

        {featuredImage &&
          <>
            <style scoped>{css}</style>
            <div className="container w-full max-w-7xl mx-auto mt-8 rounded bg-header-section blur-bg-image" style={{height: '50vh'}} >
              <div className={`container w-full max-w-7xl mx-auto mt-8 rounded full-bg-image ${img ? 'bg-image-loaded' : ''}`} 
                style={{height: '50vh', backgroundImage: img ? `url(${img.src}` : "none"}}></div>
            </div>
          </>
        }

        <div className={`container max-w-6xl mx-auto ${featuredImage ? '-mt-32' : ''}`}>
          <div className="mx-0 sm:mx-6">
            <div className="bg-white w-full p-8 md:p-24 font-serif text-xl md:text-3xl text-gray-800 leading-normal">
              {lead && <div className="text-2xl md:text-3xl mb-5">
                {lead}
              </div>}
              {!!leadMagnet && 
              <div className="md:-mx-20">
                <LeadMagnetBanner
                heading={leadMagnet.frontmatter.bannerheading}
                body_text={leadMagnet.frontmatter.bannertext}
                button_text={leadMagnet.frontmatter.bannerbuttontext}
                image_path={leadMagnet.frontmatter.featuredimage}
                image_alt_text={leadMagnet.frontmatter.featuredimagealt}
                lead_magnet_path={leadMagnet.fields.slug}
              />
              </div>}
              <div className="prose prose-indigo prose-xl max-w-7xl">
                <PostContent content={content} />
              </div>
            </div>
          </div>
        </div>
        <Signup />
      </div>
    </div>
  )
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  lead: PropTypes.string,
  leadMagnet: PropTypes.object,
  title: PropTypes.string,
  featuredImage: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data, location }) => {
  const { markdownRemark: post } = data;
  const featuredImage = post.frontmatter.featuredimage ? getCloudinaryPathOnly(post.frontmatter.featuredimage) : undefined;
  const featuredImageAlt = post.frontmatter.featuredimagealt;
  return (
    <Layout location={location} wrapperClasses="" footer_children={<SubSenseCollectionScript/>}>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        lead={post.frontmatter.lead}
        leadMagnet={post.leadMagnet}
        helmet={
          <Helmet titleTemplate="%s | Subscribe Sense Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              name="keywords"
              content={post.frontmatter.metakeywords}
            />

            <meta
              property="twitter:title"
              content={`${post.frontmatter.title}`}
            />
            <meta
              property="twitter:description"
              content={post.frontmatter.description}
            />
            {featuredImage &&
              <meta 
                property="twitter:image"
                content={`https://res.cloudinary.com/subscribe-sense-marketing/image/upload/c_fill,f_auto,q_auto,g_auto,w_1200,h_630/${featuredImage}`}
              />}
            {featuredImageAlt && 
              <meta
                property="twitter:image:alt"
                content={featuredImageAlt}
              />}
            {featuredImage ? 
              <meta 
                property="twitter:card"
                content="summary_large_image"
              /> 
            :
              <meta
                property="twitter:card"
                content="summary"
              />
            }

            <meta
              property="og:title"
              content={post.frontmatter.title}
            />
            <meta
              property="og:description"
              content={post.frontmatter.description}
            />
            <meta
              property="og:type"
              content="article"
            />
            {featuredImage  &&
              <meta 
                property="og:image"
                content={`https://res.cloudinary.com/subscribe-sense-marketing/image/upload/c_fill,f_auto,q_auto,g_auto,w_1200,h_630/${featuredImage}`}
              />}     
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        featuredImage={featuredImage}
      />
      {post.relatedPosts && post.relatedPosts.length > 0 && <>
        <div className="text-center">
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Related blog posts</h2>
        </div>
        <BlogRollTemplate data={{'allMarkdownRemark': {'edges': post.relatedPosts.map(n=>({'node': n}))}}} count={post.relatedPosts.length} />
      </>}
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        lead
        featuredimage
        featuredimagealt
        metakeywords
        tags
      }
      leadMagnet {
        fields {
          slug
        }
        frontmatter {
          featuredimage
          featuredimagealt
          bannerheading
          bannertext
          bannerbuttontext
        }
      }
      relatedPosts {
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          featuredimage
          featuredimagealt
        }
      }
    }
  }
`;
