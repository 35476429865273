
import * as React from "react";
import { Link } from "gatsby";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { getCloudinaryPathOnly } from "../utils/transformations";

export default function LeadMagnetBanner({
  heading,
  body_text,
  button_text,
  image_path,
  image_alt_text,
  lead_magnet_path,
}) {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-16 px-0 md:px-8">
        {/* <div className="bg-indigo-700 rounded-lg shadow-xl overflow-hidden flex gap-4"> */}
        <div className="bg-indigo-700 rounded-lg shadow-xl overflow-hidden flex items-center justify-center gap-4">
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 md:py-16 md:pr-0">
            <div className="lg:self-center">
              <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                <span className="block">{heading}</span>
              </h2>
              <p className="mt-4 text-lg leading-6 text-white">{body_text}</p>
              <Link to={lead_magnet_path} className="mt-8 bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50">
                {button_text}
              </Link>
            </div>
          </div>
          <div className="m-6 md:mr-10 lg:w-2/3 w-full hidden md:flex">
            <PreviewCompatibleImage
              imageInfo={{
                cldImageName: getCloudinaryPathOnly(image_path),
                // image: `https://res.cloudinary.com/subscribe-sense-marketing/image/upload/c_fill,f_auto,g_auto,q_auto,w_300/${getCloudinaryPathOnly(image_path)}`,
                alt: image_alt_text,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
