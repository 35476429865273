import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import { getCloudinaryPathOnly } from '../utils/transformations'

export class BlogRollTemplate extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    const featuredPost = posts.find(e => e.node.frontmatter.featuredpost)?.node

    return (
      <>
      {featuredPost && 
      <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-1 lg:max-w-5xl">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-2/3 rounded-t">
            <PreviewCompatibleImage 
              imageInfo={{
                cldImageName: getCloudinaryPathOnly(featuredPost.frontmatter.featuredimage), 
                // image: `https://res.cloudinary.com/subscribe-sense-marketing/image/upload/c_fill,f_auto,q_auto,w_685/${getCloudinaryPathOnly(featuredPost.frontmatter.featuredimage)}`,
                alt: `featured image thumbnail for post ${featuredPost.frontmatter.title}`,
              }}
              className="h-full w-full shadow" 
            />
          </div>
          <div className="w-full lg:w-1/3 flex flex-col flex-grow flex-shrink">
            <div className="flex-1 p-6 rounded-t rounded-b-none overflow-hidden shadow-lg">
              <Link to={featuredPost.fields.slug} className="no-underline hover:no-underline block mt-2">
                <p className="w-full font-semibold lg:font-bold text-xl text-gray-900 lg:px-6">{featuredPost.frontmatter.title}</p>
                <p className="text-gray-800 text-base lg:text-xl lg:px-6 lg:mb-5 mt-3">
                  {featuredPost.frontmatter.description}
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>}
      <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-6xl">
        {posts && posts.filter(e => !e.node.frontmatter.featuredpost).map(({ node: post }) => (
          <div key={post.id} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
            {post.frontmatter.featuredimage && <div className="flex-shrink-0">
              <PreviewCompatibleImage
                className="w-full"
                imageInfo={{
                  cldImageName: getCloudinaryPathOnly(post.frontmatter.featuredimage),
                  // image: `https://res.cloudinary.com/subscribe-sense-marketing/image/upload/c_fill,f_auto,ar_1.5,g_auto,q_auto,w_550/${getCloudinaryPathOnly(post.frontmatter.featuredimage)}`,
                  alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                }}
              />
            </div>}
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
              <div className="flex-1">
                <Link to={post.fields.slug} className="block mt-2">
                  <p className="text-xl font-semibold text-gray-900">{post.frontmatter.title}</p>
                  <p className="mt-3 text-base text-gray-500">{post.frontmatter.description}</p>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      </>
    )
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}


export default function BlogRoll() {
  return (
    <StaticQuery
      query={graphql`
        query BlogRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { 
              templateKey: { eq: "blog-post" },
              published: {eq: true}
            } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  description
                  featuredpost
                  featuredimage
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <BlogRollTemplate data={data} count={count} />}
    />
  );
}
